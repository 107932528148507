import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'
import Image from './Image'

export const ProjectTile = ({ data, loaded }) => {
    return (
            <a href={data.url ? data.url : '#'} target="_blank">
        <article className="project">
            <Image filename={data.image} />
            <div className="project--meta">
                    <h3>{data.name}</h3>
                    {data.build ?
                        <p>{data.build}</p>
                    : null}
                    {data.partners ?
                        <p>Partners: {data.partners}</p>
                    :null}
            </div>
        </article>
        </a>
    )
}

import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Projects } from "../components/Projects"
import "../styles/main.scss"
import GetInTouch from "../components/Graphics/GetInTouch"
import { Contact } from "../components/Contact"

// Create your instance
// const gradient = new Gradient()

const IndexPage = () => (
  <Layout>
    <h1 class="visually-hidden">William Barber Web Development</h1>
    <Seo title="Home" />
    <Projects />
    {/* <GetInTouch /> */}
    <Contact />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage

import React, { useEffect, useState } from 'react'
import { ProjectTile } from './ProjectTile'

const projectData = [
    {
        "name": "Life Flight",
        "image": "lf.webp",
        "url": "https://bookalifeflight.nz/",
        "build": "Shopify 2.0"
    },
    {
        "name": "Fix and Fogg",
        "image": "fixandfogg.webp",
        "url": "https://fixandfogg.co.nz/",
        "build": "Shopify 2.0, Vue.js"
    },
    {
        "name": "Bendon Lingerie",
        "image": "bendon.jpg",
        "url": "https://bendonlingerie.co.nz",
        "partners": "Pocket Square",
        "build": "Shopify 2.0, Vue.js"
    },
    {
        "name": "HereThere",
        "image": "herethere.webp",
        "url": "https://here-there.club/",
        "build": "Shopify 2.0"
    },
    {
        "name": "UBCO",
        "image": "ubco.jpg",
        "url": "https://ubco.com",
        "build": "Shopify 2.0",
        "partners": "Pocket Square"
    },
    {
        "name": "Welfe Bowyer",
        "image": "welfe.webp",
        "url": "https://welfebowyer.com",
        "partners": "Harrison Gyde",
        "build": "Shopify 2.0, Vue.js"
    },
    {
        "name": "Dive Rakiura",
        "image": "dive.webp",
        "url": "https://si-snorkel.vercel.app/",
        "build": "NextJs/Sanity"
    },
    {
        "name": "Stories of Te Reo (intro)",
        "image": "stories-of-te-reo.jpg",
        "url": "https://www.storiesoftereo.nz/",
        "build": "React, NextJs",
        "partners": "ForPurposeNz"
    },
    {
        "name": "Seven Agency",
        "image": "seven.webp",
        "url": "https://seven.co.nz/",
        "partners": "Seven Agency",
        "build": "Kirby CMS"
    },
    {
        "name": "Earshots",
        "image": "earshots.jpg",
        "url": "https://earshots.com",
        "build": "Shopify 2.0"
    },
    {
        "name": "Raukupu",
        "image": "raukupu.jpg",
        "url": "https://www.raukupu.co.nz",
        "build": "React, Kirby CMS, Python",
        "partners": "Psychoactive Studios"
    },
    {
        "name": "Templeton Group",
        "image": "templeton.jpg",
        "url": "https://www.templetongroup.co.nz",
        "build": "Kirby CMS",
        "partners": "New Territory Studio"
    },
    {
        "name": "Fortune Favours",
        "image": "fortune.jpg",
        "url": "https://fortunefavours.beer/",
        "build": "Shopify 2.0"
    },
    {
        "name": "Rock your Baby",
        "image": "rockyourbaby.webp",
        "url": "https://rockyourbaby.com/",
        "partners": "Pocket Square",
        "build": "Shopify 2.0, Vue.js"
    },
    {
        "name": "Delta Underwriting",
        "image": "delta.jpeg",
        "url": "https://deltaunderwriting.com/",
        "build": "Gatsby, Strapi",
        "partners": "New Territory"
    },
    {
        "name": "Fabric",
        "image": "fabric.webp",
        "url": "https://thisisfabric.com/pages/denim-guide",
        "build": "Shopify, JS",
        "partners": "Pocket Square"
    },
    {
        "name": "David Stevens",
        "image": "david-stevens.webp",
        "url": "https://davidstevens.co.nz",
        "build": "Contentful, Gatsby",
        "partners": "Jeremy Hooper"
    }
    // {
    //     "name": "Qula",
    //     "image": "qula.jpg",
    //     "url": "https://qula.co",
    //     "build": "Shopify"
    // }
]
export const Projects = () => {  
  return (
    <ul className='projects'>
        {projectData.map((project, i) => (
            <li key={i}>
                <ProjectTile data={project} />
            </li>
        ))}
    </ul>
  )
}

import React from "react"
import Image from "./Image"

export const Contact = () => {
  return (
    <div className="contact">
      <div className="logos">
        <div className="bi shopify-partners"></div>
        <a
          href="https://www.storetasker.com/experts/william-barber"
          target="_blank"
        >
          <div className="bi storetasker"></div>
        </a>
        <a
          href="https://www.unicornfactory.nz/profiles/william-barber"
          target="_blank"
        >
          <div className="bi unicorn-factory"></div>
        </a>
      </div>
      <b>© Barber Development &middot; {new Date().getFullYear()}</b>
    </div>
  )
}

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(webpOptions: { quality: 100 }, layout: FULL_WIDTH, aspectRatio: 1.77777777778)
              fluid {
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find((n) => {
        return n.node.fluid.originalName.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      return (
        <GatsbyImage
          key={props.key}
          alt={props.alt}
          title={props.alt}
          image={image.node.gatsbyImageData}
          aspectRatio={props.aspectRatio ? props.aspectRatio : "16/9"}
        />
      );
    }}
  />
);

export default Image;